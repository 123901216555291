import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import NaikgajiMiniLogo from "../../../assets/svg/naikgaji-mini-logo.svg";
import SecurityIcon from "../../../assets/svg/security-popup-icon.svg";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-paper": {
    background: Color.white,
    border: `2px solid ${Color.black}`,
    borderRadius: "1.5rem",

    maxWidth: "41.875rem",

    overflow: "hidden",
    zIndex: 9999,

    "@media (min-width: 1024px)": {
      "&:hover": {
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",
      },
    },

    "@media (max-width: 640px)": {
      maxWidth: "20.5rem",
    },
  },
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const TextStyled = styling(Typography)({});

export const LinkStyled = styling("a")((props) => ({
  color: "rgba(0, 0, 0, 0.6)",
  fontWeight: "400",
  fontSize: "0.875rem",
  fontStyle: "italic",

  ...(props.type === "important" && {
    display: "flex",
    alignItems: "center",
    color: Color.hiredlyPurple,
    fontWeight: "700",
    fontStyle: "none",
    height: "1.875rem",
    padding: "0 2rem",
  }),
}));

export const ButtonStyled = styling("button")((props) => ({
  background: props.solid ? Color.naikGreen : Color.white,
  color: props.solid ? Color.white : Color.naikGreen,

  border: "none",
  borderRadius: "0.375rem",

  height: "2.625rem",
  width: "12.5rem",
  padding: "0 2rem",

  fontSize: "1rem",
  fontWeight: 700,

  transition: "all 0.1s ease-in-out",

  "@media (max-width: 640px)": {
    height: "1.875rem",
    fontSize: "0.75rem",
  },

  "&:hover": {
    cursor: "pointer",
  },
}));

export const CloseButton = styling("button")({
  position: "absolute",
  background: "none",
  border: "none",
  top: "1.25rem",
  right: "1rem",

  "&:hover": {
    cursor: "pointer",
  },

  "@media (max-width: 640px)": {
    top: "1rem",
    right: "0.75rem",
  },
});

export const CloseIcon = styling(XMarkIcon)({
  width: "1.75rem",
  height: "1.75rem",
  color: Color.grey,

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },

  "@media (max-width: 640px)": {
    width: "2rem",
    height: "2rem",
  },
});

export const NaikgajiLogo = styling(NaikgajiMiniLogo)({
  minHeight: "26px",
  minWidth: "26px",
  height: "26px",
  width: "26px",
});

export const PrivacyIcon = styling(SecurityIcon)({
  height: "188px",
  width: "188px",
});
