import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import { getLocaleCopies } from "../../../helpers/locale_management";
import { updatePDPAStatus } from "../../../redux/actions/user_action";
import {
  BoxContainer,
  ButtonStyled,
  CloseButton,
  CloseIcon,
  DialogContainer,
  LinkStyled,
  NaikgajiLogo,
  PrivacyIcon,
  TextStyled,
} from "./styles";

function SharedPrivacyDialog() {
  const dispatch = useDispatch();
  const [openCheckDialog, setOpenCheckDialog] = useState(false);

  const isMobile = useMediaQuery("(max-width:640px)");
  const status = useSelector((state) => state.user.pdpaAccepted);

  useEffect(() => {
    checkDialog();
  }, [status]);

  function checkDialog() {
    if (status !== null && status !== undefined) {
      if (!status) {
        setOpenCheckDialog(true);
      }
    }
  }

  function handleClose() {
    setOpenCheckDialog(false);
  }

  function handleSubmit() {
    dispatch(updatePDPAStatus()).then(() => {
      handleClose();
    });
  }

  function handleRedirect() {
    window.open("/privacy-policy", "_blank");
  }

  const copies = getLocaleCopies();

  return (
    <DialogContainer open={openCheckDialog}>
      <BoxContainer
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "100%", height: "100%" }}
      >
        <BoxContainer
          alignItems={isMobile ? "flex-start" : "center"}
          gap={"0.625rem"}
          width={"100%"}
          height={"100%"}
          padding={"1.5rem 1.5rem 1rem"}
          borderBottom={"1px solid " + Color.grey}
        >
          <NaikgajiLogo />
          <TextStyled
            variant="h5"
            fontSize={"1.25rem"}
            lineHeight={"1.5rem"}
            fontWeight=" 700"
            width={isMobile ? "70%" : "100%"}
          >
            {copies.privacyPopupTitle}
          </TextStyled>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </BoxContainer>

        <BoxContainer
          flexDirection={"column"}
          alignItems={"center"}
          padding={isMobile ? "0.5rem 1rem 1.5rem" : "1rem"}
          gap={isMobile ? "0.5rem" : "1rem"}
        >
          <PrivacyIcon />
          <BoxContainer flexDirection={"column"} gap={"1rem"}>
            <TextStyled
              variant="p"
              fontSize={"0.875rem"}
              lineHeight={"1.25rem"}
              fontWeight={"400"}
              color={"rgba(0, 0, 0, 0.6)"}
            >
              {copies.privacyPopupContent}
            </TextStyled>
            <TextStyled
              variant="p"
              fontSize={"0.875rem"}
              lineHeight={"1.25rem"}
              fontWeight={"400"}
              color={"rgba(0, 0, 0, 0.6)"}
            >
              <LinkStyled href="/privacy-policy">
                {copies.privacyPolicy}{" "}
              </LinkStyled>{" "}
              {copies.and}{" "}
              <LinkStyled href="/terms-and-conditions">
                {copies.termsAndCondition}{" "}
              </LinkStyled>{" "}
              {copies.naikGaji}
            </TextStyled>
          </BoxContainer>
        </BoxContainer>

        <BoxContainer
          justifyContent={"center"}
          alignItems={"center"}
          gap={"0.625rem"}
          padding={"1rem 1.875rem"}
          width={"100%"}
          borderTop={"1px solid " + Color.grey}
        >
          <ButtonStyled onClick={handleRedirect} type="important">
            {copies.viewOurPolicy}
          </ButtonStyled>
          <ButtonStyled onClick={handleSubmit} solid>
            {copies.accept}
          </ButtonStyled>
        </BoxContainer>
      </BoxContainer>
    </DialogContainer>
  );
}

export default SharedPrivacyDialog;
