import CloseIcon from "@mui/icons-material/Close";
import { Select } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { styled as styling } from "@mui/material/styles";

export const Wrapper = styling(Grid)({
  height: "80px",
  backgroundColor: "white",
});

export const DrawerStyled = styling(Drawer)({
  "& .MuiDrawer-paper": {
    width: "100vw",
  },
});

export const IconButtonStyled = styling(IconButton)({});

export const CloseIconStyled = styling(CloseIcon)({});

export const EmptyWrapper = styling(Grid)({});

export const SelectStyled = styling(Select)((props) => ({
  // there is a tiny padding at the select label
  // causes misalignment with the arrow button, so we remove the padding
  "&.MuiInputBase-root .MuiSelect-select": {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
}));

export const NameStyled = styling("p")({
  margin: "0",
  fontFamily: "Karla",
  fontWeight: 700,
  fontSize: "1rem",
  color: "rgba(0, 0, 0, 0.87)",
  lineHeight: "1.5",
  padding: "6px 16px",

  maxWidth: "250px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
