import { useRouter } from "next/router";
import en from "../locales/en";
import ms from "../locales/ms";
import { localisation } from "./constant";

const getLocaleCopies = () => {
  const router = useRouter();

  const { locale } = router || {};

  if (locale === localisation.ENGLISH) {
    return en;
  } else {
    return ms;
  }
};

export { getLocaleCopies };
