import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { getLocaleCopies } from "../../../helpers/locale_management";
import { updateShowOnboardingDialog } from "../../../redux/actions/dialog_action";
import {
  AccountCircleIconStyled,
  BoxContainer,
  ButtonSolidStyled,
  ButtonStyled,
  DialogContainer,
  TextStyled,
} from "./styles";

function SharedOnboardingDialog() {
  const dispatch = useDispatch();
  const Router = useRouter();

  const isMobile = useMediaQuery("(max-width:640px)");

  const reduxUser = useSelector((state) => state.user.user);
  const status = reduxUser?.onboarding;
  const dialogOpen = useSelector((state) => state.dialog.showOnboardingDialog);

  function handleClose() {
    dispatch(updateShowOnboardingDialog(false));
  }

  function handleSubmit() {
    // current route will be saved to be redirected back later
    localStorage.setItem("resume_onboarding", Router.asPath);
    dispatch(updateShowOnboardingDialog(false));
    Router.replace("/onboarding");
  }

  const copies = getLocaleCopies();

  return (
    <DialogContainer open={dialogOpen}>
      <BoxContainer
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "100%", height: "100%" }}
        display={"flex"}
        padding={"40px 24px 24px 24px"}
        gap={"24px"}
      >
        <AccountCircleIconStyled />

        <BoxContainer
          flexDirection={"column"}
          gap={"8px"}
          display={"flex"}
          alignItems={"flex-start"}
          alignSelf={"stretch"}
        >
          <TextStyled
            variant="p"
            fontSize={"20px"}
            lineHeight={"30px"}
            fontWeight={"500"}
            color={"#000"}
            fontFamily={"Rubik"}
          >
            {copies.completeYourProfile}
          </TextStyled>
          <TextStyled
            variant="p"
            fontSize={"14px"}
            lineHeight={"20px"}
            fontWeight={"400"}
            color={"rgba(0, 0, 0, 0.87)"}
            letterSpacing={"0.112px"}
          >
            {copies.almostThereCompleteProfile}
          </TextStyled>
        </BoxContainer>

        <BoxContainer
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={"8px"}
          width={"100%"}
          alignSelf={"stretch"}
          display={"flex"}
        >
          <ButtonSolidStyled onClick={handleSubmit}>
            {copies.next}
          </ButtonSolidStyled>
          <ButtonStyled onClick={handleClose} type="important">
            {copies.cancel}
          </ButtonStyled>
        </BoxContainer>
      </BoxContainer>
    </DialogContainer>
  );
}

export default SharedOnboardingDialog;
