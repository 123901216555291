import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-paper": {
    background: Color.white,
    border: `1px solid ${Color.black}`,
    borderRadius: "20px",

    maxWidth: "328px",

    overflow: "hidden",
    zIndex: 9999,

    "@media (max-width: 640px)": {
      maxWidth: "328px",
    },
  },
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const TextStyled = styling(Typography)({});

export const ButtonSolidStyled = styling("button")((props) => ({
  display: "flex",
  height: "42px",
  width: "100%",
  padding: "4px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
  borderRadius: "6px",
  background: Color.naikGreen,
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  letterSpacing: "0.1px",
  transition: "all 0.1s ease-in-out",
  border: "none",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const ButtonStyled = styling("button")((props) => ({
  display: "flex",
  height: "42px",
  width: "100%",
  padding: "4px 8px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  alignSelf: "stretch",
  borderRadius: "6px",
  background: Color.white,
  color: " #127041",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  letterSpacing: "0.1px",
  transition: "all 0.1s ease-in-out",
  border: "none",

  "&:hover": {
    cursor: "pointer",
  },
}));

export const AccountCircleIconStyled = styling(AccountCircleIcon)({
  height: "160px",
  width: "160px",
  color: "#707070",
});
